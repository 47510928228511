<template>
  <div class="about">
    <nav-bar show-back="1">
      <div class="nav-title">Tentang kami</div>
    </nav-bar>
    <div class="about-info">
      <div class="about-item">
        <div class="about-title"><h2>Selamat datang di wowsaa !</h2></div>
        <h3>1. Company introduction:</h3>
        <div>
          Wowsaa adalah sebuah perusahaan e-commerce dengan kelompok sosial
          sebagai model utama dan fokus pada barang diskonton untuk melayani
          konsumen Indonesia.
        </div>
        <h3>2. Partners:</h3>
        <div>
          Saat ini, rekan-rekan termasuk pemilik toko, lazada, shopfiy,
          allvalue, dll. pelanggan dapat membeli produk diskonton di platform di
          atas pada wowsaa dengan harga diskonton setelah kompetisi kelompok.
        </div>
        <h3>3. informasi kontak:</h3>
        <p></p>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/navBar";
export default {
  components: { NavBar },
};
</script>
<style scoped lang="scss">
.about {
  padding-bottom: pxTo(16) pxTo(16) pxTo(16) pxTo(16);
  @extend %page;
  background-color: $c11;
  .about-info {
    padding: pxTo(16);
    background-color: #fff;
    border-radius: pxTo(10);
    width: 96%;
    margin: 0 auto;
    margin-top: pxTo(20);
    .about-item {
      padding: pxTo(8);
      width: 100%;
      height: pxTo(400);
      color: #333;
      // font-family: "Microsoft Yahei", "SimHei", "SimSun", "Lucida Console",
      //   "Courier New", monospace;
      .about-title {
        h2 {
          font-size: pxTo(20);
        }
        text-align: center;
        background-color: #fff;
        margin-bottom: pxTo(10);
        @extend %ff-rm;
        font-size: 0.8rem;
        font-weight: 400;
        color: #654CFF;
      }
      h3 {
        margin-bottom: pxTo(10);
      }
      div {
        font-size: pxTo(13);
        text-align: justify;
        // font-family: "Microsoft Yahei", "SimHei", "SimSun", "Lucida Console",
        //   "Courier New", monospace;
        // color: #333;
        overflow: hidden;
        word-wrap: break-word;//只对英文生效换行单词不分离
        word-break: break-word;
        margin-bottom: pxTo(10);
      }
    }
  }
}
</style>